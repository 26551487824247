<template>
    <div
        class="main-menu menu-fixed menu-accordion menu-shadow"
        :class="[
            { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
            skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
        ]"
        @mouseenter="updateMouseHovered(true)"
        @mouseleave="updateMouseHovered(false)"
    >
        <div class="navbar-header expanded">
            <slot
                name="header"
                :toggleVerticalMenuActive="toggleVerticalMenuActive"
                :toggleCollapsed="toggleCollapsed"
                :collapseTogglerIcon="collapseTogglerIcon"
            >
                <ul class="nav navbar-nav flex-row">
                    <li class="nav-item mr-auto">
                        <b-link
                            class="navbar-brand"
                            to="/"
                        >
                            <span class="brand-logo">
                                <b-img
                                    :src="appLogoImage"
                                    alt="logo"
                                    width="100%"
                                />
                            </span>
                        </b-link>
                    </li>

                    <li class="nav-item nav-toggle">
                        <b-link class="nav-link modern-nav-toggle">
                            <feather-icon
                                icon="XIcon"
                                size="20"
                                class="d-block d-xl-none"
                                @click="toggleVerticalMenuActive"
                            />
                            <feather-icon
                                :icon="collapseTogglerIconFeather"
                                size="20"
                                class="d-none d-xl-block collapse-toggle-icon"
                                @click="toggleCollapsed"
                            />
                        </b-link>
                    </li>
                </ul>
            </slot>
        </div>
        
        <div
            :class="{'d-block': shallShadowBottom}"
            class="shadow-bottom"
        />

        <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="main-menu-content scroll-area"
            tagname="ul"
            @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
        >
            <vertical-nav-menu-items
                :items="navMenuItems"
                class="navigation navigation-main"
            />
        </vue-perfect-scrollbar>
    </div>
</template>

<script>

    import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue';
    import { provide, computed, ref } from '@vue/composition-api';
    import navMenuItems2 from '@/navigation/vertical/index2';
    import navMenuItems3 from '@/navigation/vertical/index3';
    import navMenuItems4 from '@/navigation/vertical/index4';
    import navMenuItems1 from '@/navigation/vertical/index';
    import useAppConfig from '@core/app-config/useAppConfig';
    import VuePerfectScrollbar from 'vue-perfect-scrollbar';
    import useVerticalNavMenu from './useVerticalNavMenu';
    import { $themeConfig } from '@themeConfig';
    import { getUserData } from '@/auth/utils';

    export default {
        components: {
            VerticalNavMenuItems,
            VuePerfectScrollbar
        },
        props: {
            isVerticalMenuActive: {
                type: Boolean,
                required: true
            },
            toggleVerticalMenuActive: {
                type: Function,
                required: true
            }
        },
        setup (props) {
            const {
                isMouseHovered,
                isVerticalMenuCollapsed,
                collapseTogglerIcon,
                toggleCollapsed,
                updateMouseHovered
            } = useVerticalNavMenu(props);

            const { skin } = useAppConfig();

            const { role } = getUserData();

            const shallShadowBottom = ref(false);
            const navMenuItems = ref([]);

            if (role === 'superadmin' || role === 'admin') {
				navMenuItems.value = navMenuItems1
			} else if (role === 'provider') {
				navMenuItems.value = navMenuItems2
			} else if (role === 'reviser') {
				navMenuItems.value = navMenuItems3
			} else {
                navMenuItems.value = navMenuItems4
            }

            provide('isMouseHovered', isMouseHovered);

            const perfectScrollbarSettings = {
                maxScrollbarLength: 60,
                wheelPropagation: false
            }

            const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'));

            const { appLogoImage } = $themeConfig.app;

            return {
                navMenuItems,
                perfectScrollbarSettings,
                isVerticalMenuCollapsed,
                collapseTogglerIcon,
                toggleCollapsed,
                isMouseHovered,
                updateMouseHovered,
                collapseTogglerIconFeather,

                // Shadow Bottom
                shallShadowBottom,

                // Skin
                skin,

                // App Name
                appLogoImage
            }
        }
    }

</script>

<style lang="scss">
    @import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
