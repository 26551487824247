<template>
    <div class="navbar-container d-flex content align-items-center">

        <!-- Nav Menu Toggler -->
        <ul class="nav navbar-nav d-xl-none">
            <li class="nav-item">
                <b-link
                    class="nav-link"
                    @click="toggleVerticalMenuActive"
                >
                    <feather-icon
                        icon="MenuIcon"
                        size="21"
                    />
                </b-link>
            </li>
        </ul>

        <!-- Left Col -->
        <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
            <dark-Toggler class="d-none d-lg-block"></dark-Toggler>
        </div>

        <b-navbar-nav class="nav align-items-center ml-auto">
            <b-nav-item-dropdown
                right
                toggle-class="d-flex align-items-center dropdown-user-link"
                class="dropdown-user"
            >
                <template #button-content>
                    <div class="d-sm-flex d-none user-nav">
                        <p class="user-name font-weight-bolder mb-0">
                            {{ userData ? userData.fullName : '' }}
                        </p>
                        <span class="user-status">{{ userData ? userData.roleAlias : '-' }}</span>
                    </div>
                    <b-avatar
                        size="40"
                        variant="light-primary"
                    />
                </template>

                <b-dropdown-item
                    link-class="d-flex align-items-center"
                    :to="{ name: 'account-setting' }"
                >
                    <feather-icon
                        size="16"
                        icon="UserIcon"
                        class="mr-50"
                    />
                    <span>Perfil</span>
                </b-dropdown-item>

                <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
                    <feather-icon
                        size="16"
                        icon="LogOutIcon"
                        class="mr-50"
                    />
                    <span>Cerrar sesión</span>
                </b-dropdown-item>
                
            </b-nav-item-dropdown>
        </b-navbar-nav>
    </div>
</template>

<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue';
    import { initialAbility } from '@/libs/acl/config';
    import useJwt from '@/auth/jwt/useJwt';

    export default {
        components: {
            ToastificationContent,
            DarkToggler
        },
        data() {
            return {
                userData: JSON.parse(localStorage.getItem('userData'))
            }
        },
        props: {
            toggleVerticalMenuActive: {
                type: Function,
                default: () => {}
            }
        },
        methods: {
            async logout() {

                const response = await useJwt.logout();

                if (response.data.success)
                {
                    // Remove userData from localStorage
                    // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
                    localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
                    localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

                    // Remove userData from localStorage
                    localStorage.removeItem('userData');

                    // Reset ability
                    this.$ability.update(initialAbility);

                    // Redirect to login page
                    this.$router.push({ name: 'login' });

                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: response.data.message,
                            icon: 'CheckIcon',
                            variant: 'success'
                        }
                    });
                }

            }
        }
    }

</script>

<style scoped>

    .user-status {
        text-transform: capitalize;
    }

</style>