export default [{
        title: 'Inicio',
        route: 'home',
        icon: 'HomeIcon',
        action: 'read',
        resource: 'ACL'
    },
    {
        title: 'Tablero de control',
        icon: 'FileTextIcon',
        route: 'medisalud-forms',
        action: 'manage',
        resource: 'medisalud'
    }
]