export default [{
        title: 'Inicio',
        route: 'home',
        icon: 'HomeIcon',
        action: 'read',
        resource: 'ACL'
    },
    {
        title: 'Formularios',
        icon: 'FileTextIcon',
        route: 'provider-forms',
        action: 'manage',
        resource: 'providers'
    },
    {
        title: 'Reportes',
        icon: 'BarChart2Icon',
        route: 'provider-reports',
        action: 'manage',
        resource: 'providers'
    }
]