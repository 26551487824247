<template>
    <p class="clearfix mb-0">
        <span class="float-md-left d-block d-md-inline-block mt-25">
            COPYRIGHT © {{ new Date().getFullYear() }}
            <b-link class="ml-25" :href="appWebUrl" target="_blank"><strong>MINERA PODEROSA S.A. </strong></b-link>
            <span class="d-none d-sm-inline-block">TODOS LOS DERECHOS RESERVADOS.</span>
        </span>
    </p>
</template>

<script>

    import { $themeConfig } from '@themeConfig';

    export default {
        setup() {
			const { appWebUrl } = $themeConfig.app;
            
			return {
				appWebUrl
			}
		}
    }

</script>