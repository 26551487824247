export default [{
        title: 'Inicio',
        route: 'home',
        icon: 'HomeIcon',
        action: 'read',
        resource: 'ACL'
    },
    {
        title: 'Proveedores',
        icon: 'BriefcaseIcon',
        route: 'providers',
        action: 'manage',
        resource: 'admin'
    },
    {
        title: 'Revisores',
        icon: 'PenToolIcon',
        route: 'revisers',
        action: 'manage',
        resource: 'admin'
    },
    {
        title: 'Reportería',
        icon: 'PieChartIcon',
        children: [{
            title: 'Formularios Completados por Rubro',
            route: 'admin-reporteria-1'
        }, {
            title: 'Formularios por Estado',
            route: 'admin-reporteria-2'
        }, {
            title: 'Formularios por Nivel',
            route: 'admin-reporteria-3'
        }, {
            title: 'Formularios por Rubro y Proceso',
            route: 'admin-reporteria-4'
        }, {
            title: 'Informes Emitidos por Rubro',
            route: 'admin-reporteria-6'
        }, {
            title: 'Proveedores por Nivel',
            route: 'admin-reporteria-7'
        }, {
            title: 'Proveedores por Rubro',
            route: 'admin-reporteria-8'
        }, {
            title: 'Análisis de PU',
            route: 'admin-reporteria-9'
        }, {
            title: 'Resultados Rev. Lab.',
            route: 'admin-reporteria-10'
        }, {
            title: 'Criticidad Laboral',
            route: 'admin-reporteria-11'
        }, {
            title: 'Observaciones',
            route: 'admin-reporteria-12'
        }, {
            title: 'Reporte Diferencia Salarial',
            route: 'admin-reporteria-13'
        }]
    }
]